import { Row, Col, Card, Button } from "react-bootstrap";
import Table from "../components/Table";

import { APICore } from "../helpers/api/apiCore";
import { useEffect, useState } from "react";
import { ModalEmpresa } from "../components/Modals/Company";
import { Company } from "../interfaces/Company";

export const PageCompany = () => {
  const [stateCompanies, setStateCompanies] = useState<Company[]>([]);
  const [stateCompanyModal, setCompanyModal] = useState<boolean>(false);
  const [stateCompanyId, setCompanyId] = useState<string>("");
  const [stateDrawTable, setStateDrawTable] = useState<number>(0);

  const toggleCompanyModal = (id: string) => {
    setCompanyModal(!stateCompanyModal);
    setCompanyId(id);
  };

  const redrawTable = () => {
    setStateDrawTable(stateDrawTable + 1);
  };

  useEffect(() => {
    const api = new APICore();

    api.get("/empresa", null).then((res) => {
      setStateCompanies(res.data.length === undefined ? [res.data] : res.data);

      setStateCompanies((stateCompanies: Company[]) => {
        stateCompanies.map((cmpn: Company) => {
          cmpn.acoes = (
            <Button
              variant="success"
              className="waves-effect waves-light"
              onClick={() => toggleCompanyModal(cmpn.cnpj)}
            >
              Editar
            </Button>
          );

          cmpn.status =
            cmpn.status === 1 ? (
              <h5 className="text-success">Ativa</h5>
            ) : (
              <h5 className="text-warning">Inativa</h5>
            );
          cmpn.agendamentos_table = cmpn.agendamentos
            ? cmpn.agendamentos.toString()
            : cmpn.agendamentos;

          cmpn.agendamentos_table = cmpn.agendamentos_table?.replace(
            /,/g,
            " - "
          );

          return cmpn;
        });
        return stateCompanies;
      });
    });
  }, [stateDrawTable]);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "CNPJ",
      accessor: "cnpj_formatado",
      sort: true,
    },
    {
      Header: "Nome",
      accessor: "nome",
      sort: true,
    },
    {
      Header: "Data Cadastro",
      accessor: "data_cadastro",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
    },
    {
      Header: "Prioridade",
      accessor: "prioridade",
      sort: true,
    },
    {
      Header: "Agendamentos",
      accessor: "agendamentos_table",
      sort: true,
    },
    {
      Header: "Ações",
      accessor: "acoes",
      sort: true,
    },
  ];

  return (
    <Row className="container-fluid">
      <Col md={12}>
        <Card>
          <Card.Body>
            <div className="col">
              <div className="page-title-box">
                <div className="page-title-right">
                  <Button
                    variant="success"
                    className="waves-effect waves-light"
                    onClick={() => toggleCompanyModal("")}
                  >
                    Adicionar
                  </Button>
                </div>
                <h4 className="page-title">Empresas</h4>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table
              columns={columns}
              data={stateCompanies}
              pageSize={10}
              isSortable={true}
              pagination={true}
              isSearchable={true}
            />
          </Card.Body>
        </Card>
      </Col>
      <ModalEmpresa
        showCompany={stateCompanyModal}
        companyId={stateCompanyId}
        hideFunction={toggleCompanyModal}
        refreshTable={redrawTable}
      />
    </Row>
  );
};
