import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import FormInput from "../FormInput";
import Spinner from "../Spinner";
import { APICore } from "../../helpers/api/apiCore";
import { useForm } from "react-hook-form";
import { cnpjMask, validCNPJ } from "../../helpers/cnpj";
import { Company } from "../../interfaces/Company";

interface ModalEmpresaProps {
  companyId: string;
  showCompany: boolean;
  hideFunction: any;
  refreshTable: any;
}

export const ModalEmpresa = ({
  showCompany,
  hideFunction,
  companyId,
  refreshTable,
}: ModalEmpresaProps) => {
  const validateCompanyExists = async (cnpj: string | undefined) => {
    if (
      cnpj === undefined ||
      cnpj === null ||
      cnpj === "" ||
      (companyId !== undefined && companyId !== "")
    ) {
      return true;
    }

    if (validCNPJ(cnpj) === false) {
      return true;
    }

    cnpj = cnpj.replace(/\D/g, "");

    const api = new APICore();
    const res = await api.get(`/empresa/${cnpj}`, undefined);
    if (
      res.data !== undefined &&
      res.data !== null &&
      res.data.cnpj !== undefined &&
      res.status === 200
    ) {
      return false;
    }
    return true;
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      nome: yup.string().required("Por favor, digite o nome da empresa."),
      cnpj: yup
        .string()
        .required("Por favor, digite o CNPJ da empresa.")
        .test("valid-cnpj", "CNPJ inválido.", (value) => validCNPJ(value))
        .test("exists-cnpj", "CNPJ já cadastrado.", (value) =>
          validateCompanyExists(value)
        ),
      prioridade: yup
        .number()
        .required("Por favor, digite a prioridade.")
        .min(0, "A prioridade deve ser maior ou igual a 0.")
        .max(10, "A prioridade deve ser menor ou igual a 10."),
      status: yup.number().required("Por favor, digite o status."),
    })
  );

  const { handleSubmit, register, formState, reset, setValue, getValues } =
    useForm<Company>({
      defaultValues: {
        id: "",
        nome: "",
        cnpj: "",
        cnpj_formatado: "",
        data_cadastro: "",
        prioridade: 0,
        status: 1,
        agendamentos: ["dia_1"],
      },
      resolver: schemaResolver,
    });

  const [stateCompany, setStateCompany] = useState<Company>(getValues());

  useEffect(() => {
    reset();
    setStateCompany(getValues());
    if (
      companyId !== undefined &&
      companyId !== "" &&
      companyId !== getValues().id
    ) {
      const api = new APICore();
      api.get(`/empresa/${companyId}`, null).then((res) => {
        if (res.data !== undefined) {
          setValue("id", res.data.cnpj);
          setValue("nome", res.data.nome);
          setValue("cnpj", res.data.cnpj_formatado);
          setValue("cnpj_formatado", res.data.cnpj_formatado);
          setValue("data_cadastro", res.data.data_cadastro);
          setValue("prioridade", res.data.prioridade);
          setValue("status", res.data.status);
          let agendamentos: Array<string> = [];
          if (res.data.agendamentos === null) {
            res.data.agendamentos = [];
          } else {
            res.data.agendamentos.split(",").forEach((dia: string) => {
              agendamentos.push("dia_" + dia);
            });
          }
          setValue("agendamentos", agendamentos);
          setStateCompany(getValues());
        }
      });
    }
  }, [companyId, reset, setValue, getValues]);

  const inputChange = (e: any) => {
    const { name, value } = e.target;
    if (name.indexOf("dia_") !== -1) {
      let agendamentos = stateCompany.agendamentos;
      if (agendamentos.indexOf(name) !== -1) {
        agendamentos.splice(agendamentos.indexOf(name), 1);
      } else {
        agendamentos.push(name);
      }
      setStateCompany({ ...stateCompany, agendamentos: agendamentos });
      setValue(name, agendamentos);
      return;
    }
    setValue(name, value);
    setStateCompany({ ...stateCompany, [name]: value });
  };

  const onSubmit = (data: Company) => {
    const api = new APICore();
    data.cnpj = data.cnpj.replace(/\D/g, "");

    if (data.id === "") {
      api.create("/empresa", data).then(async (res) => {
        for (const agendamento of data.agendamentos) {
          await api.create(
            `/agendamento/${res.data.cnpj}/${agendamento.replace("dia_", "")}`,
            {}
          );
        }
      });
    } else {
      api.update(`/empresa/${data.cnpj}`, data).then(async (res) => {
        let agendamentos: Array<string> = [];
        if (res.data.agendamentos === null) {
          agendamentos = [];
        } else {
          res.data.agendamentos.split(",").forEach((dia: string) => {
            agendamentos.push("dia_" + dia);
          });
        }

        for (const agendamento of agendamentos) {
          if (data.agendamentos.indexOf(agendamento) === -1) {
            await api.delete(
              `/agendamento/${res.data.cnpj}/${agendamento.replace("dia_", "")}`
            );
          }
        }
        for (const agendamento of data.agendamentos) {
          if (agendamentos.indexOf(agendamento) === -1) {
            await api.create(
              `/agendamento/${res.data.cnpj}/${agendamento.replace(
                "dia_",
                ""
              )}`,
              {}
            );
          }
        }
        refreshTable();
        hideFunction();
      });
    }
  };

  const formDayInputs = [];

  for (let i = 1; i < 32; i++) {
    formDayInputs.push(
      <FormInput
        key={"dia_" + i}
        label={i.toString()}
        type="checkbox"
        name={"dia_" + i.toString()}
        id={"dia_" + i.toString()}
        containerClass="mb-2 mx-1"
        onChange={inputChange}
        className="form-check-success"
        checked={
          stateCompany &&
          stateCompany.agendamentos &&
          stateCompany.agendamentos.indexOf("dia_" + i.toString()) !== -1
            ? true
            : false
        }
      />
    );
  }

  return (
    <Modal show={showCompany} onHide={hideFunction}>
      <Modal.Header closeButton>
        <h4 className="modal-title">Empresa</h4>
      </Modal.Header>
      <Modal.Body>
        {companyId !== undefined &&
        companyId !== "" &&
        stateCompany.id === "" ? (
          <div className="d-flex justify-content-center">
            <Spinner color="info" size="lg" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormInput
              label="Nome"
              type="text"
              name="nome"
              id="nome"
              value={stateCompany.nome}
              placeholder="Nome"
              containerClass={"mb-3"}
              onChange={inputChange}
              required
              register={register}
              autoComplete="off"
              errors={formState.errors}
            />
            <FormInput
              label="CNPJ"
              type="text"
              name="cnpj"
              id="cnpj"
              autoComplete="off"
              value={cnpjMask(stateCompany.cnpj)}
              placeholder="00.000.000/0000-00"
              containerClass={"mb-3"}
              onChange={inputChange}
              required
              register={register}
              errors={formState.errors}
            />

            <FormInput
              label="Status"
              name="status"
              id="statuss"
              type="select"
              value={stateCompany.status}
              placeholder="Status"
              onChange={inputChange}
              containerClass={"mb-3"}
              required
              register={register}
              errors={formState.errors}
            >
              <option value="1">Ativa</option>
              <option value="0">Inativa</option>
            </FormInput>
            <FormInput
              label="Prioridade"
              type="number"
              name="prioridade"
              id="prioridade"
              placeholder="0"
              value={stateCompany.prioridade}
              containerClass={"mb-3"}
              onChange={inputChange}
              required
              register={register}
              errors={formState.errors}
            />
            <FormLabel>Agendamentos</FormLabel>
            <div className="d-flex flex-wrap">{formDayInputs}</div>

            <div className="justify-content-end d-flex">
              <Button variant="secondary" type="button" onClick={hideFunction}>
                Fechar
              </Button>
              <div className="mx-1"></div>
              <Button variant="success" type="submit">
                Salvar
              </Button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
