import React from "react";
import { RoutesProps } from "./index";


const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Error401 = React.lazy(() => import("../pages/error/Error401"));
const Error403 = React.lazy(() => import("../pages/error/Error403"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Upcoming = React.lazy(() => import("../pages/other/Upcoming"));

export const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
  {
    path: "/error-404",
    element: <Error404 />,
  },
  {
    path: "/error-401",
    element: <Error401 />,
  },
  {
    path: "/error-403",
    element: <Error403 />,
  },
  {
    path: "/error-500",
    element: <Error500 />,
  },
  {
    path: "/upcoming",
    element: <Upcoming />,
  },
];
