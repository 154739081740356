import { Row, Col, Card } from "react-bootstrap";
import Table from "../components/Table";

import { APICore } from "../helpers/api/apiCore";
import { useEffect, useState } from "react";
import { cnpjMask } from "../helpers/cnpj";

interface Schedule {
  id: number;
  cnpj: number;
  numero_solicitacao: number;
  tipo_solicitacao: string;
  periodo_solicitado: string;
  data_pedido: string;
  st_download: boolean | any;
  data_download: string;
  cnpj_formatado?: string;
}

export const PageSchedules = () => {
  const [stateSchedules, setSchedules] = useState<Schedule[]>([]);
  const [stateInterval, setStateInterval] = useState<any>(null);

  const getSchedules = () => {
    const api = new APICore();

    api.get("/pedidos", null).then((res) => {
      console.log(res.data);
      setSchedules(res.data.length === undefined ? [res.data] : res.data);

      setSchedules((stateSchedules: Schedule[]) => {
        stateSchedules.map((schedule: Schedule) => {
          schedule.st_download =
            schedule.st_download === 1 ? (
              <h5 className="text-success">Sim</h5>
            ) : (
              <h5 className="text-warning">Não</h5>
            );
          schedule.cnpj_formatado = cnpjMask(
            schedule.cnpj.toString().length === 14
              ? schedule.cnpj.toString()
              : schedule.cnpj.toString().substring(0, 14)
          );

          return schedule;
        });
        return stateSchedules;
      });
    });
  };

  useEffect(() => {
    if (stateInterval) {
      clearInterval(stateInterval);
    } else {
      getSchedules();
    }
    setStateInterval(
      setInterval(() => {
        getSchedules();
      }, 10000)
    );
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort: true,
    },
    {
      Header: "CNPJ",
      accessor: "cnpj_formatado",
      sort: true,
    },
    {
      Header: "Número solicitação",
      accessor: "numero_solicitacao",
      sort: true,
    },
    {
      Header: "Tipo solicitação",
      accessor: "tipo_solicitacao",
      sort: true,
    },
    {
      Header: "Período solicitado",
      accessor: "periodo_solicitado",
      sort: true,
    },
    {
      Header: "Data pedido",
      accessor: "data_pedido",
      sort: true,
    },
    {
      Header: "Baixado",
      accessor: "st_download",
      sort: true,
    },
    {
      Header: "Data download",
      accessor: "data_download",
      sort: true,
    },
  ];

  return (
    <Row className="container-fluid">
      <Col md={12}>
        <Card>
          <Card.Body>
            <div className="col">
              <div className="page-title-box">
                <h4 className="page-title">Pedidos</h4>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table
              columns={columns}
              data={stateSchedules}
              pageSize={10}
              isSortable={true}
              pagination={true}
              isSearchable={true}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
