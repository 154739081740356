import CryptoJS from "crypto-js";
import config from "../config";

const secret = config.SECRET_KEY;

export const decrypt = (str: string, salt: number = 4) => {
  try {
    var _strkey = atob(secret);
    var reb64 = CryptoJS.enc.Hex.parse(str);
    var text = reb64.toString(CryptoJS.enc.Base64);
    var Key = CryptoJS.enc.Base64.parse(_strkey.split(",")[1]);
    var IV = CryptoJS.enc.Base64.parse(_strkey.split(",")[0]);
    var decryptedText = CryptoJS.AES.decrypt(text, Key, {
      keySize: 128 / 8,
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decryptedText.toString(CryptoJS.enc.Utf8).substring(salt);
  } catch (e) {
    console.log("Error", e);
    return "";
  }
};

export const encrypt = (str: string | object) => {
  if (typeof str == "object") {
    str = JSON.stringify(str);
  }

  if (!str) {
    return "";
  }

  str = Math.random().toString(36).substring(2, 10) + str;
  var _strkey = atob(secret);
  var text = CryptoJS.enc.Utf8.parse(str);
  var Key = CryptoJS.enc.Base64.parse(_strkey.split(",")[1]);
  var IV = CryptoJS.enc.Base64.parse(_strkey.split(",")[0]);
  var encryptedText = CryptoJS.AES.encrypt(text, Key, {
    keySize: 128 / 8,
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  var b64 = encryptedText.toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toLocaleString();
  return eHex.toUpperCase();
};
