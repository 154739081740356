import jwtDecode from "jwt-decode";
import axios from "axios";

import config from "../../config";
import { decrypt, encrypt } from "../security";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercept to encrypt request data
axios.interceptors.request.use(
  (config) => {
    config.data = { data: encrypt(config.data) };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  // intercept to decrypt response data
  (response) => {
    response.data = decrypt(response.data);
    response.data = JSON.parse(response.data);
    if (response.data && response.data["token"]) {
      let loggedInUser = getUserFromCookie();
      if (loggedInUser) {
        console.warn("refreshing token");
        console.warn("old token", loggedInUser["token"]);
        console.warn("new token", response.data["token"]);
        loggedInUser = { ...loggedInUser, token: response.data["token"] };
        sessionStorage.setItem(
          AUTH_SESSION_KEY,
          encrypt(JSON.stringify(loggedInUser))
        );

        setAuthorization(response.data["token"]);
      } else {
        sessionStorage.removeItem(AUTH_SESSION_KEY);
        setAuthorization(null);
      }
    }
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    error.response.data = decrypt(error.response.data);
    console.warn("error -> ", error.response.data);

    if (error && error.response && error.response.status === 404) {
      window.location.href = "/error-404";
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/error-403";
    } else {
      switch (error.response.status) {
        case 401:
          message = "Informações de login inválidas";
          window.location.href = "/error-401";
          console.warn("error -> ", error.response.data);
          break;
        case 403:
          message = "Você não tem permissão para acessar este recurso";
          window.location.href = "/error-403";
          console.warn("error -> ", error.response.data);
          break;
        case 404:
          message = "Recurso não encontrado";
          window.location.href = "/error-404";
          console.warn("error -> ", error.response.data);
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "mk-auth";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  let user = sessionStorage.getItem(AUTH_SESSION_KEY);
  user = user ? decrypt(user, 8) : "";
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any = null) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any = null) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any = null) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      this.setLoggedInUser(null);
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(
        AUTH_SESSION_KEY,
        encrypt(JSON.stringify(session))
      );
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
      if (window.location.href.includes("auth/logout") === false) {
        window.location.href = "/";
      }
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      userInfo = decrypt(userInfo, 8);
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
