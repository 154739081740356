import { RoutesProps } from "./index";

import { PageCompany } from "../pages/Company";
import { Home } from "../pages/Home";
import { PageSchedules } from "../pages/Schedules";

export const protectedRoutes: RoutesProps[] = [
  {
    path: "/home",
    element: <Home />,
    roles: ["admin", "user"],
  },
  {
    path: "/empresas",
    element: <PageCompany />,
    roles: ["admin", "user"],
  },
  {
    path: "/solicitacoes",
    element: <PageSchedules />,
    roles: ["admin", "user"],
  },
];
