import React from "react";
import { RoutesProps } from "./index";

const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));

export const authRoutes: RoutesProps[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <Register />,
  },
  {
    path: "/auth/confirm",
    element: <Confirm />,
  },
  {
    path: "/auth/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/auth/logout",
    element: <Logout />,
  },
];
