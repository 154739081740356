import { Col, Row } from "react-bootstrap";

export const Home = () => {
  return (
    <Row className="justify-content-center">
      <Col md={12}>
        <h1>Home</h1>
      </Col>
    </Row>
  );
};
