import { RouteProps } from "react-router-dom";
import { authRoutes } from "./AuthRoutes";
import { otherPublicRoutes } from "./OtherRoutes";
import { protectedRoutes } from "./ProtectedRoutes";

export interface RoutesProps {
  path: RouteProps["path"];
  roles?: string[];
  element?: RouteProps["element"];
  children?: RoutesProps[];
}

const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const AllRoutes = [...otherPublicRoutes, ...authRoutes, ...protectedRoutes];

const AllFlattenRoutes = flattenRoutes(AllRoutes);
export { AllFlattenRoutes };
